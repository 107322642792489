import React from 'react'

import { ContentLayout } from '../components/Layouts'
import UserInfo from '../containers/UserInfo'

const UserInfoScreen = () => (
    <ContentLayout>
        <UserInfo />
    </ContentLayout>
)

export default UserInfoScreen
