import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect } from 'wouter'

import { setUserInfo, setUserLoggedIn } from '../actions/user'
import Login from '../components/LoginForm'

const LoginForm = () => {
  const [formState, setFormState] = React.useState({})
  const dispatch = useDispatch()
  const user = useSelector(state => state.user)

  const userLoggedIn = user?.loggedIn
  
  const onChange = (changedFields, allFields) => {
    const [email, password] = allFields
    setFormState({
      email: email.value,
      password: password.value,
    })
  }

  const onLogin = (json) => {
    fetch(`/guest/${json.id}`, {
      credentials: 'include',
    })
    .then((resp) => resp.json())
    .then((user) => {
      localStorage.setItem('email', formState.email)
      localStorage.setItem('user', JSON.stringify(user))
      localStorage.setItem('loggedIn', true)
      dispatch(setUserInfo(user))
      dispatch(setUserLoggedIn())
    })
    .catch((err) => console.log(err.message))
  }

  const onSubmit = () => {
    fetch('/login', {
      method: 'POST',
      body: JSON.stringify(formState)
    })
    .then((data) => data.json())
    .then(onLogin)
    .catch((err) => console.log(err.message))
  }

  return userLoggedIn
  ? <Redirect to="/" />
  : (
    <Login
      onChange={onChange}
      onSubmit={onSubmit}
    />
  )
}

export default LoginForm
