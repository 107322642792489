import * as Types from './types'

export const setUserInfo = (payload) => ({
  type: Types.SET_USER_INFO,
  id: payload.id,
  firstName: payload.first_name,
  lastName: payload.last_name,
  email: payload.email,
  phone: payload.phone,
})

export const setUserId = (id) => ({
  type: Types.SET_USER_ID,
  id
})

export const setUserLoggedIn = () => ({
  type: Types.SET_USER_LOGGED_IN
})
