import React from 'react'
import { useSelector } from 'react-redux'
import { Button } from 'antd'
import { useDispatch } from 'react-redux'

import { setUserInfo, setUserLoggedIn } from '../actions/user'
import CreateAccountForm  from '../components/CreateAccountForm'

const CreateAccountInfo = () => {
    const user = useSelector(state => state.user)
    const dispatch = useDispatch()
    const [formState, setFormState] = React.useState({})

    const onChange = (allFields) => {
        console.log(allFields)
        const fields = {}
        allFields.forEach((field) => {
            fields[field?.name[0]] = field?.value || ''
        })
        setFormState(fields)
    }

    const onCreate = (json) => {
        localStorage.setItem('email', json.email)
        localStorage.setItem('user', JSON.stringify(json))
        localStorage.setItem('loggedIn', true)
        dispatch(setUserInfo(json))
        dispatch(setUserLoggedIn())
    }

    const onSubmit = () => {
        fetch(`/guest/${user.id}`, {
            method: 'PUT',
            body: JSON.stringify(formState)
        })
        .then((resp) => resp.json())
        .then(onCreate)
        .catch((err) => console.log(err.message))
    }

    return (
        <>
            <CreateAccountForm onChange={onChange} />
            <div style={{ marginTop: '2rem', display: 'flex', justifyContent: 'center' }}>
                <Button type="primary" onClick={onSubmit}>Save</Button>
            </div>
        </>
    )
}

export default CreateAccountInfo
