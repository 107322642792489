import React from 'react'
import { Form, Input } from 'antd'

import { updateKeys } from '../const/keys'

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
}

const UpdateUserInfoForm = ({ user, onChange }) => (
    <Form
        {...layout}
        name="basic"
        initialValues={{ remember: true }}
        onFieldsChange={(changedFields, allFields) => {
            onChange(allFields)
        }}
    >
        {
            Object.keys(updateKeys).map((k) => (
                <Form.Item
                    key={k}
                    name={k}
                    label={updateKeys[k]}
                    rules={[{ required: true, message: `${updateKeys[k]} is a required value` }]}
                >
                    <Input defaultValue={user[k]} />
                </Form.Item>
            ))
        }
    </Form>
)

export default UpdateUserInfoForm
