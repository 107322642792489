import React from 'react'
import { Route } from 'wouter'
import { createStore } from 'redux'
import { Provider } from 'react-redux'

import rootReducer from './reducers'
import { MainLayout } from './components/Layouts'
import HomeScreen from './screens/HomeScreen'
import LoginScreen from './screens/LoginScreen'
import UserInfoScreen from './screens/UserInfoScreen'
import RsvpScreen from './screens/RsvpScreen'
import CreateAccountScreen from './screens/CreateAccountScreen'

const store = createStore(rootReducer)

const App = () => (
  <Provider store={store}>
    <MainLayout>
      <Route path="/" component={HomeScreen} />
      <Route path="/login" component={LoginScreen} />
      <Route path="/me" component={UserInfoScreen} />
      <Route path="/rsvp/:token" component={RsvpScreen} />
      <Route path="/create-account" component={CreateAccountScreen} />
    </MainLayout>
  </Provider>
)

export default App
