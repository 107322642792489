import React from 'react'
import { Layout, Menu } from 'antd'
import { HomeOutlined, LoginOutlined, UserOutlined } from '@ant-design/icons'
import { Link } from 'wouter'
import { useSelector } from 'react-redux'

const { Content, Sider, Footer } = Layout

export const MainLayout = ({ children }) => {
  const user = useSelector(state => state.user)
  const userLoggedIn = user?.loggedIn

  return (
    <Layout>
      <Sider style={{
        overflow: 'auto',
        height: '100vh',
        position: 'fixed',
        left: 0,
        backgroundColor: '#fff'
      }}>
        <Menu mode="inline" selectable={false}>
          <Menu.Item key="1" icon={<HomeOutlined />}>
            <Link href="/">Home</Link>
          </Menu.Item>
          <Menu.Item key="2" icon={userLoggedIn ? <UserOutlined /> : <LoginOutlined />}>
            <Link href={userLoggedIn ? "/me" : "/login"}>
              {
                userLoggedIn ? `${user.firstName} ${user.lastName}` : 'Login'
              }
            </Link>
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout style={{ marginLeft: 200, padding: '1rem', backgroundColor: '#fff' }}>
        <Content>
          {children}
        </Content>
        <div style={{ display: 'flex', justifyContent: 'center', bottom: '0' }}>
          <Footer style={{ backgroundColor: '#fff', display: 'block' }}>
            Made with ❤️ by Austin Brown
          </Footer>
        </div>
      </Layout>
    </Layout>
  )
}

export const ContentLayout = ({ children }) => (
  <Layout style={{ padding: '1rem', backgroundColor: '#fff' }}>
    <Content>
      {children}
    </Content>
  </Layout>
)
