export const updateKeys =  {
    firstName: 'First Name',
    lastName: 'Last Name',
    email: 'Email Address',
    phone: 'Phone Number'
}

export const createKeys = {
    ...updateKeys,
    password: 'Password',
}
