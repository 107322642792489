import React from 'react'
import { useLocation } from 'wouter'
import { useDispatch } from 'react-redux'
import { setUserId } from '../actions/user'

const RsvpScreen = ({ params: { token } }) => {
    const [, setLocation] = useLocation()
    const dispatch = useDispatch()

    React.useEffect(() => {
        const rsvp = localStorage.getItem('rsvp')
        if (rsvp) {
            setLocation('/')
        }
        fetch(`/rsvp/${token}`)
        .then((res) => res.json())
        .then((json) => {
            dispatch(setUserId(json.id))
            localStorage.setItem('rsvp', true)
            setLocation('/create-account')
        })
        .catch((err) => console.log(err.message))
    })

    return <></>
}
export default RsvpScreen
