import * as Types from '../actions/types'

const user = JSON.parse(localStorage.getItem('user') || '{}')
const initialState = {
  id: user?.id || '',
  email: user?.email || '',
  firstName: user?.firstName || '',
  lastName: user?.lastName || '', 
  phone: user?.phone || '',
  loggedIn: localStorage.getItem('loggedIn') || false,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.SET_USER_INFO:
      return {
        ...state,
        id: action.id,
        email: action.email,
        firstName: action.firstName,
        lastName: action.lastName,
        phone: action.phone,
      }
    case Types.SET_USER_LOGGED_IN:
      return {
        ...state,
        loggedIn: !state.loggedIn,
      }
    case Types.SET_USER_ID:
      return {
        ...state,
        id: action.id
      }
    default: return state
  }
}

export default reducer
