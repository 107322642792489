import React from 'react'
import { useSelector } from 'react-redux'
import { Button } from 'antd'
import { useDispatch } from 'react-redux'
import { Redirect } from 'wouter'

import { setUserLoggedIn } from '../actions/user'
import UserInfoTable from '../components/UserInfoTable'
import UpdateUserInfoForm from '../components/UpdateUserInfoForm'


const UserInfo = () => {
    const user = useSelector(state => state.user)
    const [editMode, setEditMode] = React.useState(false)
    const dispatch = useDispatch()

    const onSaveEditButtonClicked = () => {
        if (editMode) {

        } else {
            setEditMode(!editMode)
        }        
    }

    const onCancelLogoutButtonClicked = () => {
        if (editMode) {
            setEditMode(!editMode)
        } else {                
            dispatch(setUserLoggedIn())
            localStorage.setItem('loggedIn', !localStorage.getItem('loggedIn'))
        }
    }

    const saveButtonDisabled = () => {
        return true
    }
    
    if (!user?.loggedIn) {
        return <Redirect to="/" />
    }

    return (
        <>
            {
                editMode ? <UpdateUserInfoForm user={user} /> : <UserInfoTable user={user} />
            }
            <div style={{ marginTop: '2rem', display: 'flex', justifyContent: 'center' }}>
                <Button
                    onClick={onSaveEditButtonClicked}
                    disabled={editMode && saveButtonDisabled()}
                    type="primary"
                    style={{ marginRight: '1rem' }}
                >
                    {editMode ? 'Save' : 'Edit'}
                </Button>
                {
                    <Button onClick={onCancelLogoutButtonClicked} danger={!editMode}>{editMode ? 'Cancel' : 'Logout'}</Button>
                }
            </div>
        </>
    )
}

export default UserInfo
