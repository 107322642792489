import React from 'react'
import { Row, Col, Card, Typography } from 'antd'

import { ContentLayout } from '../components/Layouts'

const { Title } = Typography

const HomeScreen = () => (
  <ContentLayout>
    <Row>
      <Col span={18}>
        <Title level={3}>Austin and Petrana | Date will go here</Title>
      </Col>
    </Row>
    <Row>
      <Col span={6}>
        <div style={{ width: '85%' }}>
          <Card
            hoverable
            cover={<img alt="grams" src="https://pbs.twimg.com/profile_images/949787136030539782/LnRrYf6e_400x400.jpg" />}
          >
            Our story
          </Card>
        </div>
      </Col>
      <Col span={6}>
        <div style={{ width: '85%' }}>
          <Card
            hoverable
            cover={<img alt="grams" src="https://pbs.twimg.com/profile_images/949787136030539782/LnRrYf6e_400x400.jpg" />}
          >
            Wedding information
          </Card>
        </div>
      </Col>
      <Col span={6}>
        <div style={{ width: '85%' }}>
          <Card
            hoverable
            cover={<img alt="grams" src="https://pbs.twimg.com/profile_images/949787136030539782/LnRrYf6e_400x400.jpg" />}
          >
            Registry
          </Card>
        </div>
      </Col>
      <Col span={6}>
        <div style={{ width: '85%' }}>
          <Card
            hoverable
            cover={<img alt="grams" src="https://pbs.twimg.com/profile_images/949787136030539782/LnRrYf6e_400x400.jpg" />}
          >
            Contact
          </Card>
        </div>
      </Col>
    </Row>
  </ContentLayout>
)

export default HomeScreen