import React from 'react'
import { Descriptions } from 'antd'

import { updateKeys } from '../const/keys'

const UserInfoTable = ({ user }) => (
    <Descriptions title={`${user.firstName} ${user.lastName}`}>
        {
            Object.keys(updateKeys).map((k) => (
                <Descriptions.Item key={k} label={updateKeys[k]}>{user[k]}</Descriptions.Item>
            ))
        }
    </Descriptions>
)

export default UserInfoTable
