import React from 'react'
import { Form, Input } from 'antd'

import { createKeys } from '../const/keys'

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
}

const CreateAccountForm = ({ onChange }) => (
    <Form
        {...layout}
        name="basic"
        initialValues={{ remember: true }}
        onFieldsChange={(changedFields, allFields) => {
            onChange(allFields)
        }}
    >
        {
            Object.keys(createKeys).map((k) => (
                <Form.Item
                    key={k}
                    name={k}
                    label={createKeys[k]}
                    rules={[{ required: true, message: `${createKeys[k]} is a required value` }]}
                >
                    {
                        k === 'password' ? <Input.Password /> : <Input />
                    }
                </Form.Item>
            ))
        }
    </Form>
)

export default CreateAccountForm
