import React from 'react'
import { Typography } from 'antd'

import { ContentLayout } from '../components/Layouts'
import LoginForm from '../containers/LoginForm'

const { Title } = Typography

const LoginScreen = () => (
  <ContentLayout>
    <Title level={3}>Login</Title>
    <LoginForm />
  </ContentLayout>
)

export default LoginScreen
