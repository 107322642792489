import React from 'react'

import { ContentLayout } from '../components/Layouts'
import CreateAccountInfo from '../containers/CreateAccountInfo'

const CreateAccountScreen = () => (
    <ContentLayout>
       <CreateAccountInfo /> 
    </ContentLayout>
)

export default CreateAccountScreen
